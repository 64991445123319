import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static targets = ["country", "state", "city"];
  static values = { urlStates: String, urlCities: String };

  connect() {
    // console.log("Location controller connected");
    if (this.countryTarget.value === "GB") {
      this.changeCountry({ target: this.countryTarget });
    }
  }

  changeCountry(event) {
    let params = new URLSearchParams();
    params.append("country", event.target.value);
    params.append("target", this.stateTarget.id);
    params.append("cities_target", this.cityTarget.id);

    get(`${this.urlStatesValue}?${params}`, {
      responseKind: "turbo-stream",
    });
  }

  changeState(event) {
    if (event.target.value == "") {
      this.cityTarget.innerHTML = "";
      return;
    }

    let params = new URLSearchParams();
    params.append("country", this.countryTarget.value);
    params.append("state", event.target.value);
    params.append("target", this.cityTarget.id);

    get(`${this.urlCitiesValue}?${params}`, {
      responseKind: "turbo-stream",
    });
  }
}
