import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="event-price"
export default class extends Controller {
  static targets = ["start", "end"];

  calculatePrice() {
    const startDate = new Date(this.startTarget.value);
    let endDate = new Date(this.endTarget.value);

    this.endTarget.min = this.startTarget.value;

    if (endDate < startDate) {
      this.endTarget.valueAsDate = startDate;
      endDate = startDate;
    }
  }
}
